import { yupResolver } from '@hookform/resolvers/yup';
import dayjs from 'dayjs';
import React from 'react';
import { useForm } from 'react-hook-form';
import * as Yup from 'yup';

import { MAILTO_EMAIL } from '../../constants';
import './form.css';

// const dataLayer = window.dataLayer || [];

// function gtag() {
//   dataLayer.push(arguments);
// }

const ContactForm = () => {
  // function gtag_report_conversion(url) {
  //   var callback = function () {
  //     if (typeof url != 'undefined') {
  //       window.location = url;
  //     }
  //   };
  //   gtag('event', 'conversion', {
  //     send_to: 'AW-799858601/GiFmCKf0pcICEKm_s_0C',
  //     event_callback: callback,
  //   });
  //   return false;
  // }

  const phoneRegex = RegExp(
    /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/
  );
  const schema = Yup.object().shape({
    name: Yup.string().required('Name is required.'),
    'email-address': Yup.string()
      .email('Please provide a valid email address.')
      .required('Email is required'),
    'phone-number': Yup.string()
      .matches(phoneRegex, 'Please provide a valid phone number.')
      .required('Phone number is required.'),
    message: Yup.string().required('A message is required.'),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: 'onBlur',
    resolver: yupResolver(schema),
  });
  const formURL =
    'https://3dodu20k1m.execute-api.us-east-1.amazonaws.com/Prod/send';

  const onSubmit = (submission) => {
    // gtag_report_conversion(window.location);

    let now = dayjs();
    const dehyphenate = (text) => {
      return text
        .replace(/\-/g, ' ')
        .toLowerCase()
        .split(' ')
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
        .join(' ');
    };
    const stringFormatter = (data) => {
      let retval = '';
      for (var attribName in data) {
        retval += dehyphenate(attribName) + ': ' + data[attribName] + '\n\n';
      }
      return retval;
    };
    const toEmails = [MAILTO_EMAIL];
    // const toEmails = ['info@cityofoakscremation.com'];
    const ccEmails = ['mailer@lyda.me'];

    let data = Object.assign(
      {
        'submission-type': 'Contact',
        'time-submitted': now.format('MM/DD/YYYY HH:mm'),
      },
      submission
    );

    let subject = `Contact from ${submission.name}`;
    let message = stringFormatter(data);

    let mailObj = { toEmails, ccEmails, subject, message };

    // Create the AJAX request
    var xhr = new XMLHttpRequest();
    xhr.open('POST', formURL, true);
    xhr.setRequestHeader('Accept', 'application/json; charset=utf-8');
    xhr.setRequestHeader('Content-Type', 'application/json; charset=UTF-8');

    // Send the collected data as stringified JSON
    xhr.send(JSON.stringify(mailObj));

    xhr.onloadend = (response) => {
      console.log(`response: ${response}`);
      if (response.target.status === 200) {
        window.location.replace('https://www.cityofoakscremation.com/success');
        console.log(response, mailObj);
      } else {
        alert(
          "Oops! Something isn't quite right. Please check all fields and try again."
        );
        console.error(response);
      }
    };
  };

  return (
    <form className='form-input' onSubmit={handleSubmit(onSubmit)}>
      <label className='block'>
        <span className='text-gray-700'>Name:</span>{' '}
        <input
          type='text'
          className='form-input mt-1 block w-full'
          placeholder='Name'
          {...register('name')}
        />
        {errors.name && <div className='error-msg'>{errors.name.message}</div>}
      </label>
      <label className='block'>
        <span className='text-gray-700'>Email address:</span>
        <input
          type='text'
          className='form-input mt-1 block w-full'
          placeholder='yourname@email.com'
          {...register('email-address')}
        />
        {errors['email-address'] && (
          <div className='error-msg'>{errors['email-address'].message}</div>
        )}
      </label>
      <label className='block'>
        <span className='text-gray-700'>Phone number:</span>
        <input
          type='tel'
          className='form-input mt-1 block w-full'
          placeholder='919-555-5555'
          {...register('phone-number')}
        />
        {errors['phone-number'] && (
          <div className='error-msg'>{errors['phone-number'].message}</div>
        )}
      </label>
      <label className='block'>
        <span className='text-gray-700'>How can we help?</span>
        <textarea
          className='form-textarea mt-1 mb-4 block w-full'
          {...register('message')}
        />
        {errors.message && (
          <div className='error-msg'>{errors.message.message}</div>
        )}
      </label>
      <input
        className='py-2 px-4 bg-green-700 hover:bg-green-600 text-base text-white font-bold uppercase rounded shadow-md hover:-translate-1 cursor-pointer'
        type='submit'
        value='Submit'
      />
    </form>
  );
};

export default ContactForm;
