import React from 'react';

import groupShot from '../assets/images/contact.jpg';
import ContactForm from '../components/forms/contactForm';
import Guarantee from '../components/guarantee';
import Layout from '../components/layout';
import SEO from '../components/seo';
import StyledButton from '../components/styledButton';

const Contact = () => {
  return (
    <Layout hasHero={false} heroSize='30rem' heroImage={groupShot}>
      <SEO
        title='Contact Us | Quick Helpful Answers'
        description="We're waiting to answer your call or email. City of Oaks Cremation is a full-service funeral home providing unsurpassed service at an unmatched price."
      />

      <div className='min-h-screen mb-6 flex flex-col items-start'>
        <div className='w-full mt-10 font-serif font-hairline'>
          <h1 className='text-4xl md:text-5xl text-green-700'>
            We're here for you when you need us.
          </h1>
        </div>
        <div>
          <p className='text-xl py-2'>
            Whether you're ready to make a decision or just have a question,
            we're standing by 24/7 to help however we can.
          </p>
        </div>{' '}
        <div
          className='w-full sm:w-3/4 lg:w-1/2 my-4 px-6 py-4 self-center'
          style={{
            boxShadow:
              '0 15px 35px rgba(50,50,93,.1), 0 5px 15px rgba(0,0,0,.07)',
          }}
        >
          <p className='text-3xl text-center'>
            <strong> 919-438-1649 </strong>
          </p>
          <div className='text-center my-2'>
            <a href='tel:919-438-1649'>
              <StyledButton>Call Now</StyledButton>
            </a>
          </div>
        </div>
        <p className='text-xl py-2'>
          Our staff has answers to your questions. We promise to make this
          process bearable and affordable and we are committed to doing it with
          the utmost professionalism.
        </p>
        <div
          className='w-full sm:w-3/4 lg:w-1/2 my-4 px-6 py-4 self-center'
          style={{
            boxShadow:
              '0 15px 35px rgba(50,50,93,.1), 0 5px 15px rgba(0,0,0,.07)',
          }}
        >
          <p className='text-3xl text-center'>
            <strong> Get in touch with us: </strong>
          </p>

          <ContactForm />
        </div>
      </div>
      <Guarantee />
    </Layout>
  );
};

export default Contact;
